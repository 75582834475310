import client from '../request'
import _ from 'lodash'

const getAllPending = () => {
  return client.get(`/statistics/pending/count`)
}
const getReport = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }
  return client.get(`/statistics/report?${filterString}`)
}

const getSummary = () => {
  return client.get(`/statistics/summary`)
}

export default {
  getAllPending,
  getReport,
  getSummary
}
