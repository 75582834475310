import { proxy } from 'valtio'
import _ from 'lodash'
import { subscribe } from 'valtio'

import { getDefaultPath } from '@lib/helpers/url_sync'
const preKeys = getDefaultPath()

const initialValue = {
  adminAccess: null, // safest value
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys
}

const storageName = 'global_store'
let storedGlobal = { ...initialValue }
if (window.localStorage) {
  let result = JSON.parse(localStorage.getItem(storageName))
  if (!_.isEmpty(result)) {
    // console.log(result)
    storedGlobal = result
  }
}

export const global = proxy({
  state: storedGlobal,
  reset() {
    global.state = { ...initialValue }
  },
  toggleAll({ view, height, collapsed }) {
    let state = global.state
    if (state.view !== view || state.height !== height) {
      const height = height ? height : state.height

      global.state = {
        ...state,
        // collapsed: collapsed,
        view: view,
        height
      }
    }
  },
  clearMenu() {
    global.state = { ...global.state, openKeys: [], current: [] }
  }
})

subscribe(global, () => {
  // console.log('state.arr has changed to', global.state)
  localStorage.setItem(storageName, JSON.stringify(global.state))
})

// ToDo: remove this
export function getView(width) {
  let newView = 'MobileView'
  if (width > 1220) {
    newView = 'DesktopView'
  } else if (width > 767) {
    newView = 'TabView'
  }
  return newView
}
