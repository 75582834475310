import React from 'react'
import Routes from './router'
import AppProvider from './AppProvider'
import { checkAuthorization } from '@lib/store/auth'

const App = () => (
  <AppProvider>
    <Routes />
  </AppProvider>
)

new Promise(() => {
  checkAuthorization()
})
  .then(() => App())
  .catch((error) => console.error(error))

export default App
