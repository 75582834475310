import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import AppLocale from '@config/translation'

export default function AppProvider({ children }) {
  let locale = 'en'
  const currentAppLocale = AppLocale[locale]

  return (
    <ConfigProvider locale={'en_US'}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        {children}
        {/* </ThemeProvider> */}
      </IntlProvider>
    </ConfigProvider>
  )
}
